import api from '../library/axios';

export default {
  businessEntities() {
    return api({
      method: 'get',
      url: '/api/business-entity',
    });
  },
  businessTypes() {
    return api({
      method: 'get',
      url: '/api/business-type',
    });
  },
  companyProfile() {
    return api({
      method: 'get',
      url: '/api/company-profile',
    });
  },
  updateCompanyProfile({ data }) {
    return api({
      method: 'patch',
      url: '/api/company-profile',
      data,
    });
  },
  myProfile() {
    return api({
      method: 'get',
      url: '/api/profile',
    });
  },
};
